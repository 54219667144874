const HomeSEO = () => {
  return (
    <section className="block zero">
      <div className="fixed">
        <p>
          Компания занимается продажей недвижимости в Ростовской области. За 30 лет успешной работы
          открыто 9 филиалов в Ростове-на-Дону и Новочеркасске.
        </p>
        <h2>История</h2>
        <p>
          Фирма была основана 30 лет назад. Спустя несколько месяцев после открытия появилась служба
          юридического оформления. В 1995 году такого формата работы практически нигде не было. Эта
          служба стала новым направлением для отечественного рынка недвижимого имущества. Позже была
          интегрирована инновационная CRM-система, позволившая систематизировать базу данных о
          ростовской недвижимости, предоставлять высокий уровень сервиса.
        </p>
        <p>Всестороннее развитие продолжилось:</p>
        <ul>
          <li>
            Запуском сайтов недвижимости Ростова-на-Дону и области, дополненных возможностью
            онлайн-подбора, калькулятором для просчета кредита;
          </li>
          <li>Внедрением мотивационной программы агентского состава;</li>
          <li>
            Открытием учебного центра, созданного обучать собственный персонал, повышать
            квалификацию существующего штата;
          </li>
          <li>
            Основанием Единого ипотечного центра, чтобы обслуживать население по вопросам
            кредитования;
          </li>
        </ul>
        <p>
          Сегодня на счету фирмы около 30 000 успешных соглашений. В штате больше 120 активных
          агентов. Организация признана в профессиональной среде в России и Европе.
        </p>
        <p>
          <b>Почему нас выбирают для поиска недвижимости?</b>
        </p>
        <ul>
          <li>
            <i>Обширная внутренняя база</i>
            <p>
              «ЛЕНДЛОРД» – агентство недвижимости в Ростове-на-Дону, в Новочеркасске, в Азове, в
              Батайске с огромным каталогом объявлений. В нем содержится почти 8000 объектов разного
              типа: от комнат в коммунальных квартирах до апартаментов в элитных новостройках,
              загородных домов, земельных участков. Также есть здания бизнес-назначения для аренды и
              покупки в собственность.
            </p>
          </li>
          <li>
            <i>Автоматизированные процессы «ЛЕНДЛОРД»</i>
            <p>
              Недвижимость Ростова-на-Дону есть на официальном сайте в открытом доступе. Можно
              искать самостоятельно по встроенным фильтрам. Благодаря удобному интерфейсу легко
              найти подходящие варианты в Ростове (недвижимость в новостройках, на вторичном рынке),
              а также в любом районе донского края.
            </p>
          </li>
          <li>
            <i>Полное сопровождение</i>
            <p>
              За каждым закрепляется персональный менеджер. Он будет консультировать с момента
              обращения до приобретения права собственности. Получить первичные рекомендации можно в
              офисе агентства недвижимости Ростова-на-Дону или по телефону.
            </p>
          </li>
          <li>
            <i>Контроль качества предоставляемых услуг</i>
            <p>
              Сотрудники работают по установленным стандартам и регламентам. Возникшие спорные
              ситуации при совершении сделки решаются отделом качества.
            </p>
          </li>
          <li>
            <i>Удобство с сохранением личного времени</i>
            <p>
              Мы берем на себя все рутинные дела. Проводится подбор помещений в соответствии с
              пожеланиями заказчиков, они тщательно проверяются, далее подготавливается документация
              для совершения всех видов сделки. При необходимости можем помочь в получении
              ипотечного кредита. Консультация узких специалистов доступна во всех 9 офисах.
            </p>
          </li>
          <li>
            <i>Проверенные застройщики</i>
            <p>
              Сотрудничество осуществляется только с надежными строительными предприятиями. Все
              объекты недвижимости в Ростовской области проходят проверку на правовую чистоту.
            </p>
          </li>
          <li>
            <i>Отсутствие рисков</i>
            <p>
              Нами проводится оценка недвижимости в Ростове-на-Дону, ее рыночной стоимости, а также
              проверка легитимности документации. В 2017 году введена особая практика: после
              заключения договора выдается сертификат до 6 миллионов рублей на возмещение реально
              понесенного ущерба. Такой подход гарантирует полную безопасность в случае
              непредвиденных обстоятельств.
            </p>
          </li>
        </ul>
        <h2>Направления деятельности</h2>
        <p>Мы оказываем комплексные риелторские, брокерские и юридические услуги.</p>
        <p>Риелторское сопровождение правоприобретателей и владельцев.</p>
        <p>Здесь можно:</p>
        <ol>
          <li>Сдать, снять;</li>
          <li>Подготовить к продаже, покупке;</li>
          <li>Обменять с доплатой;</li>
          <li>Расселить;</li>
        </ol>
        <p>
          Мы работаем с загородными домами, коттеджами, таунхаусами, квартирами, комнатами на
          первичном и вторичном рынках, участками разного типа, коммерческими помещениями.
          Предоставляется помощь в поиске, проведении оценки инвестиционной привлекательности,
          определении реальной рыночной цены на недвижимость.
        </p>
        <h2>Юридическое сопровождение</h2>
        <p>
          Опытные юристы помогут в рамках закона купить недвижимость в Ростове-на-Дону, в Азове, в
          Новочеркасске, в частности:
        </p>
        <ul>
          <li>Разобраться в тонкостях сделок, связанных с недвижимым имуществом;</li>
          <li>Разработать договоры купли-продажи, аренды, дарения;</li>
          <li>Составить исковое заявление в суд;</li>
          <li>
            Решить вопросы, связанные с самовольно возведенными строениями, перепланировками в
            квартирах, комнатах;
          </li>
        </ul>
        <p>Устную консультацию с первичным анализом ситуации можно получить бесплатно.</p>
        <h2>Оформление документов</h2>
        <p>
          Менеджеры сопровождают клиентов на всех этапах сделки. Они помогают составить или получить
          необходимую документацию для продажи, покупки, аренды, обмена жилых, коммерческих
          помещений.
        </p>
        <h2>Ипотека</h2>
        <p>
          Наши брокеры помогут купить недвижимость в Ростовской области без первоначального взноса
          по двум документам. Мы подберем оптимальные условия ипотечного кредитования, отправим
          запросы в банки-партнеры. После одобрения заявок вы сможете выбрать наиболее подходящие. В
          сложных ситуациях с кредитной историей поможем найти решение. Клиенты могут получить
          бесплатную первичную консультацию ипотечного брокера в любом офисе.
        </p>
      </div>
    </section>
  );
};

export default HomeSEO;
